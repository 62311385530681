<template>
  <div>
    <a-modal
      v-model="visibleModalManageProgram"
      class="mod"
      :title="status.isEdit ? 'Form Edit Topic' : 'Form Create Topic'"
      @cancel="toggleModalManageProgram"
      centered
      :destroyOnClose="true"
      :width="1000"
    >
      <form-topic :data="newProgram" @handle-change="handleChange" />
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModalManageProgram">
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          @click.prevent="handleOkModalManageProgram"
        >
          {{ status.isEdit ? "Edit" : "Create" }} Now
        </a-button>
      </template>
    </a-modal>
    <a-modal
      v-model="visibleModalAddSession"
      class="mod"
      :title="'Add Session on Topic'"
      @cancel="visibleModalAddSession = false"
      :width="1000"
      centered
    >
      <form-add-session
        :dataProgram="newProgram"
        @handle-change="handleChange"
      />
      <template slot="footer">
        <a-button
          class="d-none"
          size="large"
          key="back"
          @click="visibleModalAddSession = false"
        >
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="default"
          :loading="loadingActionModalManageProgram"
          @click="visibleModalAddSession = false"
        >
          Close
        </a-button>
      </template>
    </a-modal>
    <div class="d-md-flex align-items center">
      <a-select
        @change="changeMapel()"
        :style="{
          display: isMobile ? 'block' : null
        }"
        :class="!isMobile ? 'mr-3' : 'mb-3'"
        style="min-width: 150px"
        v-model="selectedParams.id_mata_pelajaran"
        size="large"
      >
        <a-select-option
          v-for="subject in subjects"
          :value="subject.id"
          :key="subject.id"
          >{{ subject.nama }}</a-select-option
        ></a-select
      >
      <a-select
        style="min-width: 150px"
        @change="fetchDataProgram()"
        :style="{
          display: isMobile ? 'block' : null
        }"
        :class="isMobile ? 'mb-3' : null"
        v-model="selectedParams.id_level"
        size="large"
      >
        <a-select-option
          v-for="level in levels"
          :value="level.id"
          :key="level.id"
          >{{ level.nama }}</a-select-option
        ></a-select
      >
      <div class="ml-auto">
        <a-button
          :block="isMobile"
          @click.prevent="toggleModalManageProgram('add')"
          size="large"
          type="primary"
          ><a-icon type="plus" />Create Topic</a-button
        >
      </div>
    </div>
    <div class="mt-4">
      <a-table
        class="hasan-table-overflow"
        :columns="programColumns"
        :data-source="programDataTable"
        :pagination="programPagination"
        :loading="programLoadingTable"
        @change="handleTableChange"
        bordered
      >
        <div
          slot="action"
          slot-scope="value, record"
          class="d-flex flex-column"
        >
          <!-- <a-button
            class="mb-3 text-primary border border-primary"
            size="large"
            @click.prevent="visibleModalAddSession = true"
          >
            <a-icon type="plus" />Add Session
          </a-button> -->
          <a-button
            @click="toggleModalManageProgram('edit', record)"
            class="mb-3 text-warning border border-warning"
            size="large"
          >
            <a-icon type="edit" />Edit
          </a-button>
          <a-button
            @click="handleDeleteProgram(record)"
            class="text-danger border border-danger"
            size="large"
          >
            <a-icon type="delete" />Delete
          </a-button>
        </div>
        <a-table
          :data-source="value.sesis"
          :pagination="false"
          :columns="sessionColumns"
          slot="expandedRowRender"
          slot-scope="value"
        >
          <span slot="zoom_setting" slot-scope="text, record">
            <a-icon
              type="video-camera"
              v-if="record.zoom_setting"
              style="font-size: 20px"
              theme="twoTone"
              two-tone-color="#eb2f96"
            />
          </span>
          <span slot="tipe_assignment" slot-scope="text">
            <a-icon
              type="paper-clip"
              v-if="text == 'File Upload'"
              style="font-size: 20px; color: #1b55e3"
            />
            <a-icon
              type="align-left"
              v-if="text == 'Essay'"
              style="font-size: 20px; color: #1b55e3"
            />
            <a-icon
              type="unordered-list"
              v-if="text == 'Multiple Choice'"
              style="font-size: 20px; color: #1b55e3"
            />
          </span>
        </a-table>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import config from '@/config'
const formTopic = () => import('@/views/Teacher/Planning/New/Topic/Form')
const formAddSession = () => import('@/views/Teacher/Planning/New/Topic/FormAddSession')

const programColumns = [
  {
    title: 'Total Session',
    dataIndex: 'totalSession',
    key: 'totalSession',
    align: 'center',
  },
  {
    title: 'Topic',
    dataIndex: 'nama',
    key: 'nama',
    width: 500,
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]

const sessionColumns = [
  {
    title: 'Class',
    dataIndex: 'kelas',
    key: 'kelas',
    align: 'center',
    width: 100,
  },
  {
    title: 'Date',
    dataIndex: 'tanggal_sesi',
    key: 'tanggal_sesi',
    width: 200,
    scopedSlots: { customRender: 'tanggal_sesi' },
  },
  {
    title: 'Live',
    dataIndex: 'zoom_setting',
    key: 'zoom_setting',
    align: 'center',
    scopedSlots: { customRender: 'zoom_setting' },
  },
  {
    title: 'Assign',
    dataIndex: 'tipe_assignment',
    key: 'tipe_assignment',
    align: 'center',
    scopedSlots: { customRender: 'tipe_assignment' },
  },
]
export default {
  name: 'Program',
  components: {
    formTopic,
    formAddSession,
  },
  props: {
    subjects: {
      type: Array,
      required: true,
    },
    levels: {
      type: Array,
    },
  },
  data() {
    return {
      programColumns,
      sessionColumns,
      visibleModalManageProgram: false,
      visibleModalAddSession: false,
      loadingActionModalManageProgram: false,
      loadingDeleteProgram: false,
      programLoadingTable: false,
      programDataTable: [],
      programPagination: {},
      status: {
        isEdit: false,
        isCreate: false,
      },
      newProgram: {
        nama: null,
        subtopik: null,
        brief: null,
        newVideo: null,
        videos: [],
        newUploadList: [],
        files: [],
        deletedFiles: [],

      },
      idEdit: null,
      order: null,
      sortBy: null,
      search: null,
      selectedParams: {
        id_mata_pelajaran: null,
        id_level: null,
      },
    }
  },
  methods: {
    handleChange(payload) {
      const { value, column, type } = payload
      // console.log('parent', payload)
      if (column === 'videos' || column === 'newUploadList') {
        if (type === 'push') {
          this.newProgram[column] = [...this.newProgram[column], value]
          console.log('push', this.newProgram[column])
        } else if (type === 'splice') {
          this.newProgram[column].splice(value, 1)
        }
      } else if (column === 'files') {
        if (type === 'remove') {
          const target = this.newProgram[column].find(el => el.id === value)
          if (target) {
            this.newProgram.deletedFiles = [...this.newProgram.deletedFiles, target]
            console.log(this.newProgram.deletedFiles)
            target.isDeleted = true
            this.newProgram[column] = this.newProgram[column].filter(el => !el.isDeleted)
          }
        }
      } else {
        this.newProgram[column] = value
      }
    },
    videoUrls(data) {
      const arr = []
      for (let i = 1; i <= 5; i++) {
        if (data[`video_url${i}`] && data[`video_url${i}`] !== '') {
          arr.push(data[`video_url${i}`])
        }
      }
      return arr
    },
    toggleModalManageProgram(status, data) {
      this.visibleModalManageProgram = !this.visibleModalManageProgram
      if (!this.visibleModalManageProgram) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newProgram = {
            nama: null,
            subtopik: null,
            brief: null,
            videos: [],
            newVideo: null,
            newUploadList: [],
            files: [],
            deletedFiles: [],
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newProgram = {
            nama: data.nama,
            subtopik: data.subtopik,
            brief: data.brief,
            videos: this.videoUrls(data),
            newVideo: null,
            newUploadList: [],
            files: data.files,
            deletedFiles: [],
          }
        }
      }
    },
    handleOkModalManageProgram() {
      const content = `${this.status.isEdit ? 'Are you sure want to edit this topic?' : 'Are you sure want to create new topic'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Warning',
          description: 'All fields are required',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>{content}</div>
          ),
          onOk: async () => {
            try {
              this.loadingActionModalManageProgram = true

              const formData = new FormData()
              const filepath = `Topics/${this.selectedParams.id_mata_pelajaran}/${this.selectedParams.id_level}/`

              formData.append('id_level', this.selectedParams.id_level)
              formData.append('id_mata_pelajaran', this.selectedParams.id_mata_pelajaran)
              formData.append('nama', this.newProgram.nama)
              formData.append('subtopik', this.newProgram.subtopik)
              formData.append('brief', this.newProgram.brief)
              formData.append('filepath', filepath)

              if (this.status.isEdit) {
                formData.append('oldFiles', JSON.stringify(this.newProgram.deletedFiles))
              }

              for (let idx = 1; idx <= this.newProgram.videos.length; idx++) {
                const videoUrl = this.newProgram.videos[idx - 1]
                // console.log('url ', videoUrl)
                formData.append(`video_url${idx}`, videoUrl)
              }
              for (let idx = 0; idx < this.newProgram.newUploadList.length; idx++) {
                formData.append('topik', this.newProgram.newUploadList[idx])
              }

              const statusParam = this.status.isEdit ? `/${this.idEdit}` : ''

              await axios.post(`${config.apiUrl}/api/topik${statusParam}`, formData, {
                headers: {
                  token: localStorage.token,
                },
              })

              this.loadingActionModalManageProgram = false
              this.toggleModalManageProgram()
              this.fetchDataProgram()

              const description = this.status.isEdit ? 'Topic has been updated' : 'Topic has been created'
              this.$notification.success({
                message: 'Success',
                description,
              })
            } catch (error) {
              console.log(error)
              this.loadingActionModalManageProgram = false
              const description = this.status.isEdit ? 'Topic has not been updated' : 'Topic has not been created'
              this.$notification.error({
                message: 'Failed',
                description,
              })
            }
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Edit Now' : 'Create Now',
          cancelText: 'Cancel',
        })
      }
    },
    handleDeleteProgram(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to delete this topic?</div>
        ),
        onOk: () => {
          this.programLoadingTable = true
          this.loadingDeleteProgram = true
          this.$store.dispatch('master/DELETE_TOPIK', {
            id: data.key,
          })
            .then(isSuccess => {
              this.fetchDataProgram()
              this.loadingDeleteProgram = false
              if (isSuccess) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    'Topic has been deleted',
                })
              } else {
                this.$notification.error({
                  message: 'Failed',
                  description: 'Topic has not been deleted',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeleteProgram = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
        cancelText: 'Cancel',
      })
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.programPagination }
      this.sortBy = sorter.columnKey
      pager.current = pagination.current
      this.programPagination = pager
      // if (sorter.order === 'ascend') {
      //   this.order = 'ASC'
      // } else if (sorter.order === 'descend') {
      //   this.order = 'DESC'
      // } else {
      //   this.order = 'ASC'
      // }
      this.fetchDataProgram()
    },
    fetchDataProgram(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.programLoadingTable = true
      this.$store.dispatch('sesi/FETCH_TOPIK', { page: this.programPagination.current || 1, order: this.order, search: this.search, sortBy: this.sortBy, idMataPelajaran: this.selectedParams.id_mata_pelajaran, idLevel: this.selectedParams.id_level })
        .then(res => {
          this.programLoadingTable = false
          const pagination = { ...this.programPagination }
          pagination.total = res.total
          this.programPagination = pagination
          this.programDataTable = res.dataTable.map(el => {
            let mappedSesi = []
            if (el.sesis.length) {
              mappedSesi = el.sesis.map(sesi => {
                return {
                  ...sesi,
                  key: sesi?.id,
                  kelas: sesi?.slot?.kela?.tingkat + '-' + sesi?.slot?.kela?.simbol,
                  tanggal_sesi: moment(sesi?.tanggal_sesi).format('DD MMMM YYYY'),
                }
              })
            }
            return {
              ...el,
              key: el.id,
              totalSession: el.sesis.length + ' Session(s)',
              sesis: mappedSesi,
            }
          })
        })
    },
    async changeMapel() {
      try {
        this.$emit('changeMapel', this.selectedParams.id_mata_pelajaran)
        this.selectedParams.id_level = this.levels[0]?.id
        this.programPagination.current = 1
        await this.fetchDataProgram()
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
  },
  created() {
    this.selectedParams.id_mata_pelajaran = this.subjects[0]?.id
    this.changeMapel()
  },
  computed: {
    isValidateForm() {
      if (this.newProgram.nama) {
        return false
      }
      return true
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>

<style>
</style>
